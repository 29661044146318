import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import Icon from "../../Icon";
import Image, { ImageType } from "../../ImageComponent";
import MuxVideo from "../../MuxVideo";
import ParallaxBox from "../../ParallaxBox";
import ReplaceableLink from "../../ReplaceableLink";
import Tag from "../../Tag";
import Text from "../../Text";
import { bodyLarge, ctaSmall, h3 } from "../../Typography";

const StyledImageWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  width: 100%;
`;

const StyledImageRatioWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledImage = styled(Image)`
  height: 100%;
  transition: all 350ms cubic-bezier(0.72, 0, 0.28, 1);

  img {
    height: 100%;
    object-fit: cover;
    object-position: 0% 50%;
    max-width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  height: ${theme.fs("buttonIcon.height")};
  width: ${theme.fs("buttonIcon.width")};
  margin-left: ${theme.space("xs")};
  color: ${theme.color("primary")};
  transition: ${theme.transition()};
`;

const ItemContentWrapper = styled.div`
  position: relative;
  z-index: 99;
`;

const ItemContent = styled.div`
  display: block;
  position: relative;
  margin-top: ${theme.space("xxs")};
  max-width: ${({ highlighted }) =>
    highlighted ? "100%" : theme.spacing("grid[6]")};
`;

const ItemMeta = styled.div`
  margin-bottom: 4px;
  ${mq("2")} {
    margin-bottom: 1em;
  }
`;

const ItemTitle = styled(Text)(
  ({ highlighted }) => css`
    ${highlighted ? h3 : bodyLarge};
    color: ${theme.color(highlighted ? "primary" : "dark")};
    font-weight: ${theme.weight(
      highlighted ? "primary.bold" : "primary.regular"
    )};
    text-transform: ${highlighted ? "uppercase" : "none"};
    margin-top: ${theme.space("xxs")};
    margin-bottom: ${theme.space("xxs")};

    ${StyledIcon} {
      display: none;
    }

    ${mq("2")} {
      margin-top: ${theme.space("xs")};

      ${StyledIcon} {
        display: ${highlighted ? "inline-block" : "none"};
      }
    }
  `
);

const ItemLink = styled(Text)`
  ${ctaSmall};
  margin-top: 4px;
  ${mq("2")} {
    display: ${({ highlighted }) => (highlighted ? "none" : "block")};
    margin-top: ${theme.space("xxs")};
  }
  color: ${theme.color("primary")};
`;

const StyledMuxVideo = styled(MuxVideo)`
  .videoPlayer {
    transition: all 350ms cubic-bezier(0.72, 0, 0.28, 1);
  }
`;

const StyledCaseItem = styled(ReplaceableLink)`
  display: block;
  width: 100%;

  &:hover {
    ${ItemLink} {
      color: ${theme.color("secondary")};
    }

    ${StyledIcon} {
      transform: translateX(18px);
      color: ${theme.color("secondary")};
    }

    ${StyledImage} {
      transform: scale(1.05);
    }

    ${StyledMuxVideo} {
      .videoPlayer {
        transform: scale(1.05);
      }
    }
  }
`;

function CaseItem({
  client,
  title,
  url,
  as,
  image,
  video,
  product,
  highlighted,
  className,
}) {
  return (
    <StyledCaseItem url={url} as={as} className={className}>
      {video ? (
        <StyledMuxVideo video={video} />
      ) : (
        <StyledImageWrapper>
          <StyledImageRatioWrapper>
            <ParallaxBox scrollOffset={["-40%", "0%"]}>
              <StyledImage {...image} />
            </ParallaxBox>
          </StyledImageRatioWrapper>
        </StyledImageWrapper>
      )}

      <ItemContentWrapper>
        <ItemContent highlighted={highlighted}>
          <ItemTitle highlighted={highlighted}>
            {title}
            <StyledIcon type="arrow" />
          </ItemTitle>
          <ItemMeta>
            {client && (
              <Tag color={"dark.light"} weight="regular">
                {client}{" "}
              </Tag>
            )}
            {product && (
              <Tag color={"dark.light"} weight="regular">
                &middot; {product}
              </Tag>
            )}
          </ItemMeta>
        </ItemContent>
      </ItemContentWrapper>
    </StyledCaseItem>
  );
}

export const caseItem = {
  client: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  product: PropTypes.string,
  image: PropTypes.shape(ImageType),
  video: PropTypes.object,
  as: PropTypes.elementType,
  highlighted: PropTypes.bool,
  className: PropTypes.string,
};
CaseItem.propTypes = caseItem;

export default CaseItem;
